import React, { Component } from 'react';
import { FirebaseContext } from '../../../contexts/firebase.context';
import Dropzone from 'react-dropzone';

import Notification from './Notification';

import ReactLoading from 'react-loading';

class AdminNewListingForm extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      notify: false,
      loading: false,
      id: null,
      title: '',
      description: '',
      images: null,
      size: '',
      downPayment: '',
      monthlyPayment: '',
      cashPrice: '',
      address: '',
      city: '',
      state: '',
      county: '',
      closestCity: '',
      zoning: '',
      access: '',
      GPSCoordinates: '',
      googleMapsUrl: '',
      power: '',
      water: '',
      septic: '',
      annualTaxes: '',
      HOAFees: '',
      videoURL: '',
      paymentLink: '',
      mapEmbed: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.createListing = this.createListing.bind(this);
    this.generateImgURLs = this.generateImgURLs.bind(this);
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  }
  handleSubmit(evt) {
    evt.preventDefault();
    this.createListing();
    // reset state after uploading listing
    this.setState({ city: '', state: '' });
  }

  handleDrop(files) {
    this.setState({ images: files });
  }
  hideNotification() {
    this.setState({ notify: false });
  }

  async createListing() {
    const firebase = this.context;
    const db = firebase.firestore();
    try {
      this.setState({ loading: true })
      // create listing with form data
      // add everyone EXCEPT FOR the images
      let docRef = await db.collection('listings').add({
        sold: false,
        title: this.state.title,
        description: this.state.description,
        downPayment: this.state.downPayment,
        monthlyPayment: this.state.monthlyPayment,
        cashPrice: this.state.cashPrice,
        city: this.state.city,
        state: this.state.state,
        county: this.state.county,
        size: this.state.size,
        address: this.state.address,
        closestCity: this.state.closestCity,
        GPSCoordinates: this.state.GPSCoordinates,
        googleMapsUrl: this.state.googleMapsUrl,
        zoning: this.state.zoning,
        access: this.state.access,
        power: this.state.power,
        water: this.state.water,
        septic: this.state.septic,
        annualTaxes: this.state.annualTaxes,
        HOAFees: this.state.HOAFees,
        paymentLink: this.state.paymentLink,
        mapEmbed: this.state.mapEmbed,
      });

      // generate image URLs for the uploaded images - this is BEFORE the id update because of how long it takes to execute
      let URLs = await this.generateImgURLs(docRef.id);

      // create id for each listing
      await db.collection('listings').doc(docRef.id).update({ id: docRef.id });
      
      let imageURLs = [];
      for (var i = 0; i < URLs.length; i++) {
        imageURLs.push({
          original: URLs[i],
          thumbnail: URLs[i]
        });
      }

      // account for YouTube video links
      if (this.state.videoURL) {
        imageURLs.push({
          original: this.state.videoURL,
          thumbnail: this.state.videoURL
        })
      }

      // update the listing so that it has the uploaded images
      await db.collection('listings').doc(docRef.id).update({ images: imageURLs });
      this.setState({ notify: true, loading: false });
    }
    catch(error) {
      console.log('Could not create listing: ', error);
    }
  }

  async generateImgURLs(docRefId) {
    if (this.state.images !== null) {
      try {
        let firebase = this.context;
        let storageRef = await firebase.storage().ref().child('listings');
        let folderRef = storageRef.child(`${docRefId}/`);
        let images = this.state.images;
        let URLs = [];

        for (let i = 0; i < images.length; i++) {
          let imgRef = await folderRef.child(images[i].name.replace(/\b.JPG\b/g, '.jpg'));
          await imgRef.put(images[i]);
          let URL = await imgRef.getDownloadURL();
          URLs.push(URL);
        }

        return URLs;
      } 
      catch (error) {
        console.log(error);
      }
    }
  }
  
  render() {
    const { notify, loading, images } = this.state;
    
    return (
      <div className='AdminNewListingForm'>
        <Notification notify={notify} msg={`Listing has been successfully created!`} handleClick={this.hideNotification} />

        {loading ? <div className='ReactLoading'><ReactLoading type={'spin'} color={'#565656'} width={100} height={100} /></div> :
        <div className='section'>
          <h2 className='is-size-3 has-text-centered'>Create a New Listing</h2>
          <div id='field-group'>
            <div className='field'>
              <label className='label'>Listing Title</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='title' value={this.state.title} placeholder='Listing Title' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Listing Description</label>
              <div className='control'>
                <textarea className='textarea' onChange={this.handleChange} name='description' value={this.state.description} placeholder='Listing Description'></textarea>
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>Down Payment</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='downPayment' value={this.state.downPayment} placeholder='$500 down, 10% down, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Monthly Payment</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='monthlyPayment' value={this.state.monthlyPayment} placeholder='$234/month for 5 years, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Cash Price</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='cashPrice' value={this.state.cashPrice} placeholder='$8,900, etc.' />
                  </div>
                </div>
              </div>
            </div>
            <Dropzone onDrop={this.handleDrop} multiple={true}>
              {({ getRootProps, getInputProps }) => (
                <section className='field'>
                  <div { ...getRootProps() } id='dropzone' className='file'>
                    <label className='file-label'>
                      <input { ...getInputProps() } className='file-input' />
                      <span className='file-cta'>
                        <span className='file-icon'>
                          <i className={`fas ${!images ? 'fa-upload' : 'fa-check has-text-success'}`} />
                        </span>
                        <span className='file-label'>
                          {images === null
                            ? 'Upload images...'
                            : 'Images waiting to be uploaded'
                          }
                        </span>
                      </span>
                    </label>
                  </div>
                </section>
              )}
            </Dropzone>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>City</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='city' value={this.state.city} placeholder='City' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>State</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='state' value={this.state.state} placeholder='State' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>County</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='county' value={this.state.county} placeholder='County' />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Size</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='size' value={this.state.size} placeholder='2.5 acres, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Address</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='address' value={this.state.address} placeholder='Address' />
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>GPS Coordinates</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='GPSCoordinates' value={this.state.GPSCoordinates} placeholder='38.313168, -105.465348, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Google Maps Link</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='googleMapsUrl' value={this.state.googleMapsUrl} placeholder='https://goo.gl/maps/8yL1a5Y7TDDi8yDBA, etc.' />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Zoning</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='zoning' value={this.state.zoning} placeholder='Residential, agriculture, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Access</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='access' value={this.state.access} placeholder='Dirt road, etc.' />
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>Power</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='power' value={this.state.power} placeholder='On property, N/A, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Water</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='water' value={this.state.water} placeholder='Water well, N/A, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Septic</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='septic' value={this.state.septic} placeholder='Septic' />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Annual Taxes</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='annualTaxes' value={this.state.annualTaxes} placeholder='$123/year, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>HOA/Fees</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='HOAFees' value={this.state.HOAFees} placeholder='$100/year, N/A, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>YouTube URL for Property Videos</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='videoURL' value={this.state.videoURL} placeholder='https://www.youtube.com/watch?v=SWYqp7iY_Tc, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Map Embed Link</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='mapEmbed' value={this.state.mapEmbed} placeholder={`<iframe frameBorder='0' height='520px' width='100%' src='https://mapright.com/ranching/maps/42acd826b76ab3dc9c27df87028386e8/embed'></iframe>`} />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Payment Link</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='paymentLink' value={this.state.paymentLink} placeholder='http://www.paymentlink.com/listing/18sod8dl238/, etc.' />
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <button className='button is-link' onClick={this.handleSubmit}>Create Listing</button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    )
  }
}

export default AdminNewListingForm;
