import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ListingsCard extends Component {
  static defaultProps = {
    city: 'Austin',
    state: 'TX',
    images: [{
      original: 'https://images.unsplash.com/photo-1520950237264-dfe336995c34?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2167&q=80',
      thumbnail: 'https://images.unsplash.com/photo-1520950237264-dfe336995c34?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2167&q=80'
    }],
    canManage: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };

    this.handleSoldToggle = this.handleSoldToggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(st => ({ modalOpen: !st.modalOpen }));
  }
  handleSoldToggle() {
    this.props.toggleSoldStatus(this.props.id);
  }
  handleDelete() {
    this.props.deleteListing(this.props.id);
    this.toggleModal();
  }
  render() {
    const { modalOpen } = this.state;
    const { id, imgSize, canManage, images, sold, title, monthlyPayment } = this.props;
    return (
      <div className="bulma">
        <div className='ListingsCard card'>
          <div className='card-image'>
            <figure className={`image ${imgSize}`}>
              <img src={images[0].original} alt={`${title}`}/>
            </figure>
          </div>
          <div className='card-content'>
            <div className='content listing-card-title'>
              {`${title}`}
            </div>
            <div className='content listing-card-subtitle'>
              {`${monthlyPayment}`}
            </div>
          </div>
          <div className={`buttons ${!canManage ? 'is-hidden' : ''}`} style={{ margin: '0.5em 1.25em' }}>
            <Link to={`/dashboard/listings/${id}/edit`} className='button is-small is-outlined'>Edit</Link>
            <button className={`button is-small ${!sold ? 'is-primary' : 'is-warning'}`} onClick={this.handleSoldToggle}>Mark as {!sold ? 'Sold' : 'Active'}</button>
            {sold &&
            <button className='button is-small is-outlined is-danger' onClick={this.toggleModal}>Delete</button>}
          </div>

          <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
            <div className='modal-background'></div>
            <div className='modal-card'>
              <div className='modal-card-body'>
                <p className='modal-card-title'>Are you sure you want to delete this listing?</p>
                <div className='buttons'>
                  <div className='button is-danger is-outlined' onClick={this.handleDelete}>Yes, I'm Sure</div>
                  <div className='button is-outline' onClick={this.toggleModal}>Never Mind</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListingsCard;