import React, { createContext } from 'react';
import firebase from '../app/firebase';

export const FirebaseContext = createContext();
export const FirebaseConsumer = FirebaseContext.Consumer;

export function FirebaseProvider(props) {
  return (
    <FirebaseContext.Provider value={firebase}>
      {props.children}
    </FirebaseContext.Provider>
  );
}