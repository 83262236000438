import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const testimonials = [
  {
    body: 'After looking at land for months, Clint was the ONLY person who took the time to help me find the perfect property. Others just kept trying to sell sell sell. Great deal and even better property!',
    imageUrl: 'https://randomuser.me/api/portraits/men/72.jpg',
    name: 'Able W.',
    purchaseInfo: '12 acres for hunting',
  },
  {
    body: 'Great company to work with, they made the buying process seamless!',
    imageUrl: 'https://randomuser.me/api/portraits/women/3.jpg',
    name: 'Mimi J.',
    purchaseInfo: '40 acres for farming',
  },
  {
    body: 'Great land deals with no credit check. Clint is the real deal!',
    imageUrl: 'https://randomuser.me/api/portraits/men/22.jpg',
    name: 'Chris L.',
    purchaseInfo: '5 acres for recreation',
  }
];

function Testimonials() {
  const [ index, setIndex ] = useState(0);

  const navigateLeft = () => {
    if (index === 0) {
      setIndex(testimonials.length - 1);
    } else {
      setIndex(index - 1);
    }
  };
  const navigateRight = () => {
    if (index === testimonials.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <section className='testimonials'>
      <h2 className='testimonials-header'>See what our satisfied customers have to say</h2>
      <div className="testimonials-gallery">
        <button className='testimonials-gallery-nav-left' onClick={navigateLeft}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cheveron-left-circle"><circle cx="12" cy="12" r="10" className="primary"/><path className="secondary" d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"/></svg>
        </button>

        <CSSTransition classNames='testimonial' timeout={200} in={true}>
          <div className="testimonials-card">
            <p className="testimonials-card-text">
              <span>&ldquo;</span>
              {testimonials[index].body}
              <span>&rdquo;</span>
            </p>
            <div className="testimonials-card-info">
              <img src={testimonials[index].imageUrl} alt="Customer" className="testimonials-card-info-image"/>
              <div className="testimonials-card-info-text-content">
                <h4 className="testimonials-card-info-name">{testimonials[index].name}</h4>
                <h5 className="testimonials-card-info-location">Purchased {testimonials[index].purchaseInfo}</h5>
              </div>
            </div>
          </div>
        </CSSTransition>

        <button className='testimonials-gallery-nav-right' onClick={navigateRight}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cheveron-right-circle"><circle cx="12" cy="12" r="10" className="primary"/><path className="secondary" d="M10.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"/></svg>
        </button>
      </div>
    </section>
  )
}

export default Testimonials;
