import React, { Component } from 'react';

class Notification extends Component {
  static defaultProps = {
    msg: 'Success!',
    error: false
  }
  
  render() {
    const { msg, notify, error, handleClick } = this.props;
    return (
      <div className='Notification'>
        <div className={`notification ${!error ? 'is-success' : 'is-danger' } ${notify ? '' : 'is-hidden'}`}>
          <button className='delete' onClick={handleClick}></button>
          {msg}
        </div>
      </div>
    )
  }
}

export default Notification;