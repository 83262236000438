import React, { useContext } from 'react';
import { Link, Switch, Route} from 'react-router-dom';
import { AuthedUserContext } from '../../../contexts/authedUser.context';
import './AdminDashboard.scss';

import Login from '../login/Login';
import ListingsEdit from '../listings/ListingsEdit';
import ListingsManage from '../listings/ListingsManage';
import AdminNewListingForm from '../listings/AdminNewListingForm';
import AdminAccountSettings from '../listings/AdminAccountSettings';

function AdminDashboard() {
  const { authedUser } = useContext(AuthedUserContext);

  // if (!authedUser) return <Login />

  return (
    <div className='AdminDashboard bulma'>
      {!authedUser 
        ? <Login />
        : <div className='container'>
            <div className='columns is-desktop'>
              <div className='column is-one-quarter-desktop'>
                <aside className='menu AdminDashboard-sidebar'>
                  <p className='menu-label'>
                    NAVIGATION
                  </p>
                  <ul className='menu-list'>
                    <li><Link to='/dashboard/manage'>Manage Listings</Link></li>
                    <li><Link to='/dashboard/create'>Create New Listing</Link></li>
                    <li><Link to='/dashboard/settings'>Account Settings</Link></li>
                  </ul>
                </aside>
              </div>

              <div className='column is-three-quarters-desktop'>
                <h1 className='is-size-2 has-text-centered'>Welcome to the Admin Dashboard</h1>
                  <Switch>
                    <Route path='/dashboard/manage' component={ListingsManage} />
                    <Route path='/dashboard/create' component={AdminNewListingForm} />
                    <Route path='/dashboard/settings' component={AdminAccountSettings} />
                    <Route path='/dashboard/listings/:id/edit' component={ListingsEdit} />
                  </Switch>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default AdminDashboard;
