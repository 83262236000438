import React, { Component } from 'react';
import { FirebaseContext } from '../../../contexts/firebase.context';

import Notification from './Notification';

class AdminAccountSettings extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      notify: false,
      error: false,
      msg: '',
    };
    this.onChange = this.onChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
  }
  onChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  }
  async updatePassword() { 
    const firebase = this.context;
    try {
      let user = firebase.auth().currentUser;
      if (this.state.password1 === this.state.password2) {
        let newPassword = this.state.password1;
        await user.updatePassword(newPassword);
        this.setState({ notify: true, msg: 'Password successfully changed!'});
      } else {
        this.setState({ notify: true, error: true, msg: 'Passwords do not match.'});
      }
      // reset values to default
      this.setState({ password1: '', password2: ''});
    }
    catch (error) {
      console.log(error);
      this.setState({ notify: true, error: true, msg: 'Oops, something went wrong. Please try again!' });
    }
  }
  hideNotification() {
    this.setState({ notify: false, error: false, msg: '' });
  }
  render() {
    const { password1, password2, notify, msg, error } = this.state;
    return (
      <div className="bulma">
        <div className='AdminAccountSettings section'>
          <h2 className='is-size-3 has-text-centered'>Account Settings</h2>
          <Notification notify={notify} error={error} msg={msg} handleClick={this.hideNotification} />
          <div className='field-group'>
            <div className='field'>
              <label className='label'>New Password</label>
              <div className='control'>
                <input className='input' type='password' name='password1' value={password1} onChange={this.onChange} />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Confirm Password</label>
              <div className='control'>
                <input className='input' type='password' name='password2' value={password2} onChange={this.onChange} />
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <button className='button is-warning' onClick={this.updatePassword}>Update Password</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminAccountSettings;
