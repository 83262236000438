import React, { Component } from 'react';
import { FirebaseContext } from '../../../contexts/firebase.context';
import ListingsCard from './ListingsCard';

class ListingsManage extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      listings: []
    };
    this.deleteListing = this.deleteListing.bind(this);
    this.toggleSoldStatus = this.toggleSoldStatus.bind(this);
  }
  componentDidMount() {
    const firebase = this.context;
    let listings = [];
    
    firebase.firestore().collection('listings').get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          listings.push(doc.data());
        });
        this.setState({ listings });
      })
      .catch((error) => {
        console.log(error);
      }) 
  }
  deleteListing(id) {
    const firebase = this.context;
    let db = firebase.firestore();
    db.collection('listings').doc(id).delete()
      .then(() => {
        console.log('Listing successfully deleted.');
      })
      .catch((error) => {
        console.log(error);
      })
    
    // let folderRef = firebase.storage().ref().child('listings').child(`${id}/`);
    // folderRef.delete()
    //   .then(() => {
    //     console.log('Storage folder successfully deleted.')
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
  }
  async toggleSoldStatus(id) {
    const firebase = this.context;
    try {
      let docRef = await firebase.firestore().collection('listings').doc(id);
      let doc = await docRef.get();
      let soldStatus = await doc.data().sold;
      await docRef.update({ sold: !soldStatus });
    }
    catch (error) {
      console.log(error);
    }
  }
  render() {
    const { listings } = this.state;
    const activeListings = listings.filter(listing => listing.sold === false);
    const soldListings = listings.filter(listing => listing.sold === true);

    return (
      <div className='ListingsManage bulma'>
        {activeListings.length > 0 &&
        <section className='section'>
          <h2 className='is-size-3 has-text-centered' style={{ marginBottom: '1em' }}>Manage Active Listings</h2>
          <div className='columns is-multiline'>
            {activeListings.map((listing, idx) => (
              <div className='column is-one-third-tablet' key={idx}>
                <ListingsCard 
                  {...listing} 
                  canManage={true} 
                  deleteListing={this.deleteListing}
                  toggleSoldStatus={this.toggleSoldStatus}
                />
              </div>
            ))}
          </div>
        </section>}
        {soldListings.length > 0 && 
        <section className='section'>
          <h2 className='is-size-3 has-text-centered' style={{ marginBottom: '1em' }}>Manage Sold Listings</h2>
          <div className='columns is-multiline'>
            {soldListings.map((listing, idx) => (
              <div className='column is-one-third-tablet' key={idx}>
                <ListingsCard 
                  {...listing} 
                  canManage={true} 
                  deleteListing={this.deleteListing}
                  toggleSoldStatus={this.toggleSoldStatus}
                />
              </div>
            ))}
          </div>
        </section>}
        {activeListings.length <= 0 && soldListings.length <= 0 &&
        <section className='section'>
          <h2 className='is-size-3 has-text-centered' style={{ marginBottom: '1em' }}>No Listings to Manage!</h2>
        </section>}
      </div>
    )
  }
}

export default ListingsManage;