import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import { AuthedUserContext } from './contexts/authedUser.context';

import './App.scss';

// Import local components
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

import Home from './components/home/Home';
import About from './components/about/About';
import Login from './components/admin/login/Login';
// import Banner from "./components/banner/Banner"

import Listings from './components/listings/Listings';
import ListingDetails from './components/listings/listingDetails/ListingDetails';

import AdminDashboard from './components/admin/dashboard/AdminDashboard';

// import ComingSoon from './components/coming-soon/ComingSoon';

function App() {
  // const { authedUser } = useContext(AuthedUserContext);

  // if (!authedUser) return <ComingSoon />
  // if (false) return <ComingSoon /> // delete this and uncomment above!!!

  return (
    <div className="App">
      <Navbar />
      {/* <Banner /> */}
      
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/listings" component={Listings} />
        <Route exact path="/listings/:id" component={ListingDetails} />
        <Route path='/dashboard' component={AdminDashboard} />
        {/* Add admin routes here */}
        <Route render={() => <h1>Page not found.</h1>} />
      </Switch>
      
      <Footer />
    </div>
  );
}

export default App;
