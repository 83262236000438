import React, { useState, createContext } from 'react';

export const AuthedUserContext = createContext();
export const AuthedUserConsumer = AuthedUserContext.Consumer;

export function AuthedUserProvider(props) {
  const [ authedUser, setAuthedUser ] = useState(null);

  return (
    <AuthedUserContext.Provider value={{ authedUser, setAuthedUser }}>
      {props.children}
    </AuthedUserContext.Provider>
  );
};