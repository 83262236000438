import React, { useContext, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useParams } from 'react-router-dom';
import { FirebaseContext } from '../../../contexts/firebase.context';

function ListingDetails(props) {
  const { id } = useParams();
  const firebase = useContext(FirebaseContext);
  const [ imgIndex, setImgIndex ] = useState(0);
  const [ listing, setListing ] = useState(null);

  useEffect(() => {
    const fetchListingFromDatabase = async () => {
      try {
        const db = firebase.firestore();
        const listingDoc = await db.collection('listings').doc(id).get();
        setListing(listingDoc.data());
      }
      catch (err) {
        console.error(err);
      }
    };

    fetchListingFromDatabase();
  }, [ id, firebase ]);

  useEffect(() => {
    // Google Analytics
      listing && window.gtag('config', 'UA-136837852-1', {
        'page_title'  : `Individual Listing Page - ${listing.title.toLowerCase().replace(/ /g, '-')}`,
        'page_path'   : `/listings/${listing.title.toLowerCase().replace(/ /g, '-')}`
      });
  }, [ listing ]);

  const navigateLeft = () => {
    if (imgIndex === 0) {
      setImgIndex(listing.images.length - 1);
    } else {
      setImgIndex(imgIndex - 1);
    }
  };
  const navigateRight = () => {
    if (imgIndex === listing.images.length - 1) {
      setImgIndex(0);
    } else {
      setImgIndex(imgIndex + 1);
    }
  };

  const touchHandlers = useSwipeable({
    onSwipedLeft: (eventData) => navigateRight(),
    onSwipedRight: (eventData) => navigateLeft()
  });

  if (!listing) {
    return (<h1>Loading...</h1>);
  }

  // sort media so YouTube video is always first to show in gallery
  const mediaItems = listing ? listing.images.sort((a,b) => {
    if (a.original.match(/youtube/) && !b.original.match(/youtube/)) return -1;
    else if (!a.original.match(/youtube/) && b.original.match(/youtube/)) return 1;
    else return 0;
  }) : [];

  return (
    <main className='listing-details'>
      <div className="container">
        <header className="listing-details-img-gallery" {...touchHandlers}>
          {mediaItems[imgIndex].original.match(/gif|jpg|JPG|jpeg|png|webp/)
            ? <img src={mediaItems[imgIndex].original} alt={`Listing`}/>
            : <div className="iframe-wrapper"><iframe src={mediaItems[imgIndex].original} width="560" height="315" frameborder="0" allow="accelerometer; encrypted-media; gyroscope;" allowfullscreen title="Drone Footage"></iframe></div>
          }
          <button className='nav-left' onClick={navigateLeft}>&lsaquo;</button>
          <button className='nav-right' onClick={navigateRight}>&rsaquo;</button>
        </header>

        <section className="overview">
          <div className="overview-content">
            <div className="overview-heading">
              <div className="overview-flex-container">
                <h2>{listing.title}</h2>
                <h3>{listing.cashPrice}</h3>
              </div>
              <p className='overview-locale'>{listing.county} County, {listing.state}</p>
              {/* <div className="overview-highlights">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-location-pin"><g><path className="secondary" d="M12 1v6a3 3 0 0 0 0 6v9.31a1 1 0 0 1-.7-.29l-5.66-5.66A9 9 0 0 1 12 1z"/><path className="primary" d="M12 1a9 9 0 0 1 6.36 15.36l-5.65 5.66a1 1 0 0 1-.71.3V13a3 3 0 0 0 0-6V1z"/></g></svg>
                {listing.size}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-bolt"><circle cx="12" cy="12" r="10" className="primary"/><path className="secondary" d="M14 10h2a1 1 0 0 1 .81 1.58l-5 7A1 1 0 0 1 10 18v-4H8a1 1 0 0 1-.81-1.58l5-7A1 1 0 0 1 14 6v4z"/></svg>
                Nearby Power
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-sign"><path className="primary" d="M14 12h5l3 3-3 3h-5v4h-4v-4H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h6v-1h4v1z"/><path className="secondary" d="M10 4a2 2 0 1 1 4 0h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H5L2 7l3-3h5z"/></svg>
                Great Location
              </div> */}
            </div>

            {/* <div className="overview-local">
              <h3>Local Information</h3>
              <div className="overview-local-gallery">
                <div className="overview-local-gallery-card">
                  <div className="overview-local-gallery-card-img"></div>
                  <h4>Map View</h4>
                  <p>Explore the area around this property</p>
                </div>

                <div className="overview-local-gallery-card">
                  <div className="overview-local-gallery-card-img"></div>
                  <h4>Things To Do</h4>
                  <p>Nearby attractions and points of interest</p>
                </div>

                <div className="overview-local-gallery-card">
                  <div className="overview-local-gallery-card-img"></div>
                  <h4>Visit the Property</h4>
                  <p>How to access the property</p>
                </div>
              </div>
            </div> */}

            <div className="overview-description">
              <h3>Description</h3>
              <p>{listing.description}</p>
            </div>
          </div>
          
          <aside className="overview-cta">
            <div className="overview-cta-panel">
              <h3 className='overview-cta-header'>
                This property is yours for only {listing.downPayment} down!
              </h3>
              <p className='overview-cta-info'>
                Once you click the Buy Now button and complete the Checkout Process, we will receive an instant notification that your {listing.downPayment} deposit has been made. $249 of the deposit is reserved as a non-refundable closing fee.
                <br/><br/>
                Financing is Available for the Property with a Down Payment of as little as {listing.downPayment} and Monthly Payments as low as {listing.monthlyPayment}. The {listing.downPayment} Deposit will serve as your Down Payment.
                <br/><br/>
                The remaining balance may be paid by Wire Transfer, Cashier’s Check, or ACH Bank Draft. We make the whole process super simple and we can complete your purchase in as little as 1 day. All payments made are non refundable.
                <br/><br/>
                Call or Text us anytime at (512) 572-7120 for questions or to purchase this property.
              </p>
              <a href={listing.paymentLink} target="_blank" rel="noopener noreferrer" className="overview-cta-btn">BUY NOW</a>
            </div>
          </aside>
        </section>

        <section className="property-details">
          <h3>Property Details</h3>
          <ul className="property-details-bullets">
            <li><span>Address:</span> {listing.address}</li>
            <li><span>County:</span> {listing.county}</li>
            <li><span>State:</span> {listing.state}</li>
            <li><span>Zoning:</span> {listing.zoning}</li>
            <li><span>Access:</span> {listing.access}</li>
            <li><span>GPS Coordinates:</span> {listing.GPSCoordinates}</li>
            <li><span>Power:</span> {listing.power}</li>
            <li><span>Water:</span> {listing.water}</li>
            <li><span>Septic:</span> {listing.septic}</li>
            {listing.downPayment && <li><span>Down Payment:</span>{listing.downPayment}</li>}
            {listing.monthlyPayment && <li><span>Monthly Payment:</span> {listing.monthlyPayment}</li>}
            <li><span>Cash Price:</span> {listing.cashPrice}</li>
            <li><span>HOA/Fees:</span> {listing.HOAFees}</li>
            <li><span>Annual Taxes:</span> {listing.annualTaxes}</li>
            <li class='invisible'><span>Building Requirements:</span> <a href="http://google.com">Link</a></li> {/* DELETE .invisible class when Building Requirements function built out */}
          </ul>
        </section>

        {listing.mapRightEmbedURL &&
        <section className='map-embed'>
          <iframe className='map-embed-iframe' src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAbQA29NfzbvykZ_EGyucg9MJb3uaLdVss&q=${listing.GPSCoordinates}&maptype=satellite`} width="600" height="450" frameBorder="0" style={{ border: 0}} allowFullScreen={false} title="Property Map"></iframe>
          {/* <iframe className='map-embed-iframe' src={listing.mapRightEmbedURL} width="600" height="450" frameBorder="0" style={{ border: 0}} allowFullScreen={false} title="Property Map"></iframe> */}
        </section>}
      </div>
    </main>
  )
}

export default ListingDetails
