import * as firebase from 'firebase';

// initialize firebase
var config = {
  apiKey: "AIzaSyAbQA29NfzbvykZ_EGyucg9MJb3uaLdVss",
  authDomain: "awaylandco.firebaseapp.com",
  databaseURL: "https://awaylandco.firebaseio.com",
  projectId: "awaylandco",
  storageBucket: "awaylandco.appspot.com",
  messagingSenderId: "602029283131"
};

firebase.initializeApp(config);
export default firebase;