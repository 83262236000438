import React, { useEffect } from 'react';
import aboutImg from '../../assets/images/about-us.jpeg';

function About() {
  useEffect(() => {
    window.gtag('config', 'UA-136837852-1', {
      'page_title'  : 'About Page',
      'page_path'   : '/about'
    });
  }, []);

  return (
    <div className='about'>
      <header className='about-header'>
        <h1>Away Land Co</h1>
        {/* <p>
          We make marketing software, video series, and educational content based on the belief that anyone can use video to grow their business and their brand.
        </p> */}
        <p>
          We help others to find great deals on raw land for hunting, agriculture, personal, and other uses.  We know buying land can be intimidating so we try to be as transparent as possible about the buying process and terms. Learn more about our personal story below.
        </p>
      </header>

      <main className='about-main'>
        <h2>Our Story</h2>
        <img src={aboutImg} alt="Owners of Away Land Co"/>

        <p>
          Hi friends!
          <br/><br/>
          We are Clint and Katheryn Turner, owners here at Away Land Co.
          <br/><br/>
          It's our goal to make land buying personal and accessible for everyone. We understand that purchasing land can sometimes be intimidating, and it's hard to know who you're dealing with online.
          <br/><br/>
          We would love to learn more about your land investing goals over a quick phone call. If you see any properties you are interested in, or just have some questions, please give us a call or email at the details below.
          <br/><br/>
          With Love and Land,
          <br/>
          Clint & Kat
        </p>
      </main>
    </div>
  )
}

export default About;
