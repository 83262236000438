import React, { useRef, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { AuthedUserContext } from '../../../contexts/authedUser.context';
import { FirebaseContext } from '../../../contexts/firebase.context';

function Login(props) {
  const firebase = useContext(FirebaseContext);
  const { authedUser, setAuthedUser } = useContext(AuthedUserContext);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await firebase.auth().signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
      setAuthedUser(response.user);
      // console.log(response);
      props.history.push('/dashboard/manage');
    }
    catch(err) { console.log(err) }
  };

  if (authedUser) {
    props.history.push('/dashboard/manage');
    return null;
  }

  return (
    <main className='coming-soon'>
      <div className="coming-soon-body">
        <h1>You must log in to access this site:</h1>
        <div className="coming-soon-form">
          <input type="email" ref={emailRef} placeholder="Email" />
          <input type="password" ref={passwordRef} placeholder="Password" />
          <input type="submit" value="Log in" onClick={handleLogin}/>
        </div>
      </div>
    </main>
  )
}

export default withRouter(Login);
