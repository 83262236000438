import React, { useEffect } from 'react';

import Hero from './hero/Hero';
import Highlights from './highlights/Highlights';
import Testimonials from './testimonials/Testimonials';

function Home() {
  useEffect(() => {
    window.gtag('config', 'UA-136837852-1', {
      'page_title'  : 'Home Page',
      'page_path'   : '/home'
    });
  }, []);

  return (
    <main className='Home'>
      <Hero />
      <Highlights />
      <Testimonials />
      {/* <EmailCapture /> */}
    </main>
  )
}

export default Home;
