import React, { Component } from 'react';
import { FirebaseContext } from '../../../contexts/firebase.context';
import Dropzone from 'react-dropzone';

import Notification from './Notification';
import './ListingsEdit.scss';

class ListingsEdit extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      videoURL: '',
      notify: { isShowing: false, msg: ``},
      isModalOpen: false,
      isLoading: false,
      currentImage: null
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.updateVideoFiles = this.updateVideoFiles.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
    this.cancelDeleteImage = this.cancelDeleteImage.bind(this);
    this.deleteImageFromListing = this.deleteImageFromListing.bind(this);
    this.addNewImageFilesToState = this.addNewImageFilesToState.bind(this);
    this.pushListingChangesToDatabase = this.pushListingChangesToDatabase.bind(this);
    this.generateNewImageURLs = this.generateNewImageURLs.bind(this);
    this.createNewImageFilesFromURLs = this.createNewImageFilesFromURLs.bind(this);
  }
  async componentDidMount() {
    // console.log(this.state); // ======= DELETE ========
    
    try {
      let firebase = this.context;
      let id = this.props.match.params.id;
      let doc = await firebase.firestore().collection('listings').doc(id).get();
      console.log(doc.data()) // ====== DELETE ======
      this.setState({ ...doc.data() }, () => {
        let videoURL;
        if (this.state.images) {
          this.state.images.map(image => {
            if (image.original.match(/youtube\.com\/embed/) !== null) {
              videoURL = image.original;
            }
            return image;
          });
        }
        this.setState({ videoURL: videoURL });
      });
    }
    catch (error) {
      console.log(error);
    }
  }
  async handleSubmit(evt) {
    evt.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.newImgFiles) {
      await this.addNewImageFilesToState();
    }
    this.updateVideoFiles();
    await this.pushListingChangesToDatabase();
  }
  async addNewImageFilesToState() {
    const imgURLs = await this.generateNewImageURLs();

    const newImgFiles = await this.createNewImageFilesFromURLs(imgURLs);

    this.setState((oldState) =>
     oldState.images ?
      ({
        images: [...oldState.images, ...newImgFiles]
      }) :
      ({
        images: [...newImgFiles]
      })
    )
  }
  async generateNewImageURLs() {
    const firebase = this.context;
    if (this.state.newImgFiles) {
      try {
        let folderRef = await firebase.storage().ref().child('listings').child(`${this.state.id}/`);
        let images = this.state.newImgFiles;
        let URLs = [];

        for (let i = 0; i < images.length; i++) {
          let imgRef = await folderRef.child(images[i].name.replace(/\b.JPG\b/g, '.jpg'));
          await imgRef.put(images[i]);
          let URL = await imgRef.getDownloadURL();
          URLs.push(URL);
        }

        return URLs;
      } 
      catch (error) {
        console.log(error);
      }
    }
  }
  createNewImageFilesFromURLs(URLs) {
    let newImgFiles = [];

    for (var i = 0; i < URLs.length; i++) {
      newImgFiles.push({
        original: URLs[i],
        thumbnail: URLs[i]
      });
    }

    return newImgFiles;
  }
  updateVideoFiles() {
    let newImages = this.state.images;
    let hasExistingVideo = false;

    this.state.images.forEach(image => {
      if (image.original.match(/youtube\.com\/embed/)) {
        hasExistingVideo = true;
      }
    });

    if (this.state.videoURL && hasExistingVideo) {
      newImages = this.state.images.map(image => {
          if (image.original.match(/youtube\.com\/embed/) && this.state.videoURL !== '') {
            image.original = this.state.videoURL;
            image.thumbnail = this.state.videoURL;
          }
          return image;
        });
    }
    else if (this.state.videoURL && !hasExistingVideo) {
      newImages = this.state.images.concat([{
        original: this.state.videoURL,
        thumbnail: this.state.videoURL
      }])
    }
    else if (!this.state.videoURL && hasExistingVideo) {
      newImages = this.state.images.filter(image => {
        return image.original.match(/youtube\.com\/embed/) === null
      });
    }

    this.setState({ images: newImages });
  }
  async pushListingChangesToDatabase() {
    try {
      const firebase = this.context;
      const doc = await firebase.firestore().collection('listings').doc(this.state.id);
      console.log('Inside of pushListingChangestoDatabase function: ', this.state)
      await doc.update({ 
        ...this.state,
        videoURL: firebase.firestore.FieldValue.delete(),
        notify: firebase.firestore.FieldValue.delete(), 
        isModalOpen: firebase.firestore.FieldValue.delete(), 
        currentImage: firebase.firestore.FieldValue.delete(),
        newImgFiles:  firebase.firestore.FieldValue.delete(),
        isLoading: firebase.firestore.FieldValue.delete(),
      });
      this.setState({ 
        notify: { isShowing: true, msg: `Changes have been saved!` }, 
        newImgFiles: null,
        isLoading: false
      });
      console.log('Document should have updated.')
    }
    catch (error) {
      console.log(error);
    }
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }
  handleDrop(files) {
    this.setState({ newImgFiles: files }, () => console.log(this.state));
  }

  handleDeleteImage(imgThumbnail) {
    this.setState({
      isModalOpen: true,
      currentImage: imgThumbnail
    });
  }
  cancelDeleteImage() {
    this.setState({
      isModalOpen: false,
      currentImage: null
    });
  }
  async deleteImageFromListing() {
    const imgToDelete = this.state.currentImage;
    const imgThumbnail = this.state.currentImage;

    if (this.state.images.length > 1) {
      if (imgToDelete !== null) {
        // delete image from current state
        this.setState((oldState) => ({
          ...oldState,
          currentImage: null,
          isModalOpen: false,
          images: oldState.images.filter((img) => img.thumbnail !== imgThumbnail),
        }));
      }
    } else {
      this.setState({
        notify: { isShowing: true, msg: `Error deleting image: You must always have at least one image per listing!` },
        isModalOpen: false,
      });
    }
  }

  hideNotification() {
    this.setState({ notify: { isShowing: false, msg: `` } });
  }
  
  render() {
    const { notify, images, newImgFiles, isLoading } = this.state;
    // console.log(images);
    
    if (!this.state) return;

    return (
      <div className='ListingsEdit bulma'>
        <h2 className='is-size-3 has-text-centered'>Edit Listing</h2>

        <Notification notify={notify.isShowing} msg={notify.msg} handleClick={this.hideNotification} />
 
        <section className='section'>
          <div id='field-group'>
            <div className='field'>
              <label className='label'>Listing Title</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='title' value={this.state.title} placeholder='Listing Title' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Listing Description</label>
              <div className='control'>
                <textarea className='textarea' onChange={this.handleChange} name='description' value={this.state.description} placeholder='Listing Description'></textarea>
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>Down Payment</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='downPayment' value={this.state.downPayment} placeholder='$500 down, 10% down, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Monthly Payment</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='monthlyPayment' value={this.state.monthlyPayment} placeholder='$234/month for 5 years, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Cash Price</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='cashPrice' value={this.state.cashPrice} placeholder='$8,900, etc.' />
                  </div>
                </div>
              </div>
            </div>
            
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>City</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='city' value={this.state.city} placeholder='City' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>State</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='state' value={this.state.state} placeholder='State' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>County</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='county' value={this.state.county} placeholder='County' />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Size</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='size' value={this.state.size} placeholder='2.5 acres, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Address</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='address' value={this.state.address} placeholder='Address' />
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>GPS Coordinates</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='GPSCoordinates' value={this.state.GPSCoordinates} placeholder='38.313168, -105.465348, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Google Maps Link</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='googleMapsUrl' value={this.state.googleMapsUrl} placeholder='https://goo.gl/maps/8yL1a5Y7TDDi8yDBA, etc.' />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Zoning</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='zoning' value={this.state.zoning} placeholder='Residential, agriculture, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Access</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='access' value={this.state.access} placeholder='Dirt road, etc.' />
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>Power</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='power' value={this.state.power} placeholder='On property, N/A, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Water</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='water' value={this.state.water} placeholder='Water well, N/A, etc.' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Septic</label>
                  <div className='control'>
                    <input className='input' onChange={this.handleChange} type='text' name='septic' value={this.state.septic} placeholder='Septic' />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Annual Taxes</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='annualTaxes' value={this.state.annualTaxes} placeholder='$123/year, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>HOA/Fees</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='HOAFees' value={this.state.HOAFees} placeholder='$100/year, N/A, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>YouTube URL for Property Video (Drone Footage)</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='videoURL' value={this.state.videoURL} placeholder='https://www.youtube.com/watch?v=SWYqp7iY_Tc, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Map Embed Link</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='mapEmbed' value={this.state.mapEmbed} placeholder={`<iframe frameBorder='0' height='520px' width='100%' src='https://mapright.com/ranching/maps/42acd826b76ab3dc9c27df87028386e8/embed'></iframe>`} />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Payment Link</label>
              <div className='control'>
                <input className='input' onChange={this.handleChange} type='text' name='paymentLink' value={this.state.paymentLink} placeholder='http://www.paymentlink.com/listing/18sod8dl238/, etc.' />
              </div>
            </div>
            <div className='field'>
              <label className="label">
              Edit Images
              </label>
              <div className="control edit-images-container">
                <div className="add-new-container">
                  <Dropzone onDrop={this.handleDrop} multiple={true}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div { ...getRootProps() } id='dropzone'>
                          <input { ...getInputProps() } />
                          {!newImgFiles
                            ? <p>
                                Add New Image<span className="icon has-text-success"><i className="fas fa-plus"></i></span>
                              </p>
                            : <p>
                                Images Waiting to Be Uploaded <span className="icon has-text-info"><i className="fas fa-check-square"></i></span>
                              </p>
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="image-card-container">
                  {images && images.map((image, idx) => (
                    <div className="image-card" key={idx}>
                      {image.thumbnail.match(/jpg|JPG|png|gif|webp/) 
                        ? <img src={image.thumbnail} alt="Listing Thumbnail"/>
                        : <iframe src={image.thumbnail} frameBorder="0" title="Listing Footage"></iframe>
                      }
                      <div className="buttons">
                        {/* <button className="button is-small is-warning">
                          <span className="icon is-small">
                            <i className="far fa-edit"></i>
                          </span>
                        </button> */}
                        <button className="button is-small is-danger" onClick={() => this.handleDeleteImage(image.thumbnail)}>
                          <span className="icon is-small">
                            <i className="fas fa-trash"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <button className='button btn-add-new is-success is-large is-rounded'>Add New Image</button> */}
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <button className={`button is-success ${isLoading && 'is-loading'}`} onClick={this.handleSubmit} disabled={isLoading}>
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </section>
        
        <aside className={`modal ${this.state.isModalOpen ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <p>
              Are you sure you want to delete this image from the listing? (This <strong>cannot</strong> be undone!)
            </p>
            <button className='button is-outlined is-danger' onClick={this.deleteImageFromListing}>Yes</button>
            <button className='button is-primary' onClick={this.cancelDeleteImage}>No</button>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={this.cancelDeleteImage}></button>
        </aside>
      </div>
    )
  }
}

export default ListingsEdit;
