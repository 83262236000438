import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { FirebaseProvider } from './contexts/firebase.context';
import { AuthedUserProvider } from './contexts/authedUser.context';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <FirebaseProvider>
      <AuthedUserProvider>
        <App />
      </AuthedUserProvider>
    </FirebaseProvider>
  </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
